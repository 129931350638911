<template>
  <v-main class="mx-5">
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          v-model="orderInput"
          label="Numéro de commande"
          placeholder="Numéro de commande"
          :rules="orderIdRules"
          type="text"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-row>
          <v-btn
            color="#015C53"
            dark
            class="mt-5"
            @click.stop="() => getOrderStock(orderInput)"
          >
            Vérifier
          </v-btn>
          <v-dialog
            v-model="dialogOrder"
            persistent
            min-width="500"
            max-width="1000"
          >
            <v-card>
              <v-row class="initialiseMargin">
                <v-card-title class="text-h5 mx-3">
                  Récapitulatif commande
                </v-card-title>
                <v-spacer></v-spacer>
                <v-card-title class="mx-3"> Aide: 07 56 80 80 21 </v-card-title>
              </v-row><v-row v-if="this.order && checkAdmin()" class="my-3">
          <v-col>
            <v-card height="180" class="text-center ml-4" >
              <div>
                <h2 class="text-subtitle-1 pb-5">{{ getUserName() }}</h2>
                <p class="text-body-1 black--text text-lowercase">{{ this.order.userAddress }}
                  <br>{{ this.order.userPostalCode }}, {{ this.order.userCity }}
                  <br>{{ this.order.userEmail }}
                  <br>{{ this.order.userPhone }}
                </p>
              </div>
            </v-card>
          </v-col>
          <v-col>
            <v-card height="180" class="text-center mr-4">
              <div>
                <h2 class="text-subtitle-1 pb-5">{{this.order.pharmacyName}}</h2>
                <p class="text-body-1 black--text text-lowercase">{{ this.order.pharmacyAddress }}
                  <br>{{ this.order.pharmacyPostalCode }}, {{ this.order.pharmacyCity }}
                  <br>{{ this.order.pharmacyEmail }}
                  <br>{{ this.order.pharmacyPhone }}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <div class="mx-auto text-center text-h6 mb-5" v-if="!checkAdmin()">
          {{ getUserName() }}
        </div>
              <v-card-text>
                <v-list>
                  <div class="mx-auto text-center text-h6 mb-5">
                    {{ getUserName() }}
                  </div>
                  <v-divider></v-divider>
                  <template>
                    <v-list-item v-for="item in getProducts()" :key="item.ean">
                      <template>
                        <v-list-item-action>
                          <v-btn
                            small
                            color="red lighten-2"
                            @click="report(item)"
                          >
                            signaler une erreur
                          </v-btn>
                        </v-list-item-action>
                        <v-spacer></v-spacer>
                        <v-list-item-content>
                          <v-list-item-title
                            style="white-space: initial"
                          >{{ item.name }}</v-list-item-title>
                          <v-list-item-subtitle>
                            EAN : {{ item.ean }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            Quantité : {{ item.quantity }}   
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Prix TTC : {{ item.price * item.quantity }}€
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < getProducts().length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialogOrder = false">
                  Annuler
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogConfirm = true"
                  v-if="this.order && this.order.status != 'completed'"
                >
                  Valider la commande
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogReport" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 red lighten-2">
                Annuler la commande d'un produit
              </v-card-title>
              <v-card-text class="text-center pa-12">
                En confirmant, le produit sera retiré de la commande et le
                remboursement s'effectuera sous 48H
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#015C53" text @click="closeDialogReport"
                  >Annuler</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="confirmReport"
                  >Retirer le produit</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogConfirm" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 green lighten-2">
                Valider la commande
              </v-card-title>
              <v-card-text class="text-center pa-12">
                Vous confirmez que les produits listés ont été restitués au
                client.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#015C53" text @click="dialogConfirm = false"
                  >Annuler</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn color="#015C53" @click="validOrder" class="white--text"
                  >Valider la commande</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
      <v-snackbar v-model="snackbar" bottom color="#015C53"
        >{{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            Fermer
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-main>
</template>
<script>
import { ordersCollection, logsCollection } from "../main";
import moment from "moment";
export default {
  data: () => ({
    uidAdmin: process.env.VUE_APP_UID_ADMIN,
    selected: [],
    canceled: [],
    orderInput: "",
    message: null,
    snackbar: false,
    dialogOrder: false,
    dialogConfirm: false,
    dialogReport: false,
    order: null,
    productToReport: null,
    fullName: "",
    docId: "",
    orderIdRules: [(v) => !!v || "Le numéro de commande est obligatoire"],
  }),
  methods: {
    async getOrderStock(orderInput) {
      try {
        ordersCollection
          .where("orderID", "==", orderInput)
          .get()
          .then(async (querySnapshot) => {
            let order = {};
            if (!querySnapshot.empty) {
              let data = querySnapshot.docs[0].data();
              let id = querySnapshot.docs[0].id;
              let checkAccess =
                data.products[0].supplier == localStorage.getItem("uid") ||
                this.checkAdmin();
              if (checkAccess) {
                  order = {
                    docId: id,
                    status: data.status,
                    orderID: data.orderID,
                    name:
                      data.user.userName ??
                      `${data.user.firstname} ${data.user.lastname}`,
                    userEmail:
                      data.user.userEmail,
                    userAddress: data.user.userAddress,
                    userPostalCode: data.user.userPostalCode,
                    userCity: data.user.userCity,
                    userPhone: data.user.userPhone,

                    products: data.products,
                    totalPrice: data.totalPrice ?? data.products
                      .reduce((acc, product) => {
                        return acc + product.price;
                      }, 0)
                      .toFixed(2),
                    
                    pharmacyName: data.pharmacyName,
                    pharmacyAddress: data.pharmacyAddress,
                    pharmacyPostalCode: data.pharmacyPostalCode,
                    pharmacyCity: data.pharmacyCity,
                    pharmacyEmail: data.pharmacyEmail,
                    pharmacyPhone: data.pharmacyPhone,

                    date: data.created_at
                      ? moment
                          .unix(data.created_at["seconds"])
                          .format("YYYY/MM/DD HH:mm:ss")
                      : data.date,
                  };
              }
            }
            if (order == null) {
              this.message =
                "Aucune commande en cours ne correspond au numéro saisi";
              this.snackbar = true;
            } else {
              this.order = order;
              this.docId = order["docId"];
              this.dialogOrder = true;
            }
          });
      } catch (error) {
        this.message = "Aucune commande en cours ne correspond au numéro saisi";
        this.snackbar = true;
      }
    },

    checkAdmin() {
      let valResult = this.uidAdmin === localStorage.getItem("uid");
      return valResult;
    },

    getUserName() {
      if (this.order != null) {
        let result = this.order["name"];
        return result;
      } else return "";
    },
    getProducts() {
      if (this.order != null) {
        return this.order.products.filter(
          (product) => product.canceled != true
        );
      } else return [];
    },

    //Display a dialog to report a product and cancel it from the order
    report(product) {
      this.productToReport = product;
      this.dialogReport = true;
    },

    confirmReport() {
      this.cancelProduct(this.productToReport).then(() => {
        this.closeDialogReport();
      });
    },

    //Cancel the product selected ("productToReport")
    //Add a line in the firestore collection 'logs' and update the collection 'orders'
    //Update this.orders
    async cancelProduct(productToReport) {
      await logsCollection.add({
        event: "refund",
        product: productToReport,
        datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
        orderId: this.order.orderID,
        uid: localStorage.getItem("uid"),
      });
      let index = this.order["products"].findIndex((product) => {  
        return product["ean"] == productToReport["ean"] && 
        (
          product["supplier"] === localStorage.getItem("uid") ||
          this.checkAdmin()
        )
      });

      // delete this.order["products"][index]["validated"];

      this.order["products"][index]["validated"] = false;
      this.order["products"][index]["canceled"] = true;

      await ordersCollection
        .doc(this.docId)
        .update({ products: this.order["products"] });
    },
    closeDialogReport() {
      this.productToReport = null;
      this.dialogReport = false;
      this.dialogOrder = false;
      this.dialogConfirm = false;
    },
    async validOrder() {
      if (this.order.status == "validated") {
        await ordersCollection.doc(this.order.docId).update({
          status: "prepared",
          timestamp: moment().format("YYYY/MM/DD HH:mm:ss"),
        });
        this.order.status = "prepared";
      } else {
        await ordersCollection.doc(this.order.docId).update({
          status: "completed",
          timestamp: moment().format("YYYY/MM/DD HH:mm:ss"),
        });
        this.order.status = "completed";
      }

      this.order = null;
      this.dialogConfirm = false;
      this.dialogOrder = false;
    },

    async checkOrderFinished() {
      await ordersCollection
        .doc(this.order.docId)
        .update({products: this.order.products });
    },
  },
};
</script>
<style>
.initialiseMargin {
  margin: 0;
}
</style>
