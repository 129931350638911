<template>

  <div class="container-bg">
    <div>
      <div class="container-content">
  
        <div class="listeDons">
  
          <div v-if="checkAdmin()" class="search rounded-pill mdi mdi-magnify">
            <input type="text" v-model="search" placeholder="Rechercher">
          </div> 
  
          <div class="top-content">
            <div class="tabs">
              <button
                v-for="(tab, index) in tabs"
                :key="index"
                :class="['tab rounded-pill', { active: activeTab === index, inactive: activeTab !== index }]"
                @click="changeTab(index)"
              >
                {{ tab.title }}
              </button>
            </div>
          </div>
  
          <div class="data">
            <div class="tableau">
              <div class="tableau-title">
                <div>Numéro</div>
                <div class="name">Donateur</div>
                <div class="bis action">PT achat HT</div>
                <div class="bis action">Réduction fiscale</div>
                <div class="bis action">Quantité min.</div>
                <div class="action">Action</div>
              </div>
  
              <div v-if="activeTab == 0">
                <div class="historique" v-for="(donation, index) in filteredDonations" :key="index">
                  <div>{{ donation.donationId }}</div>
                  <div class="name">{{ donation.pharmacyName }}</div>
                  <div class="bis action">{{ formatPrice(donation.totalPrice) }} €</div>
                  <div class="bis action">{{ formatPrice(donation.taxReduction) }} €</div>
                  <div class="bis action"> {{ getOrderSize(donation) }} / {{ donation.minimalDonationCart }}</div>
                  <div class="action">
                    <v-icon small class="mr-2" @click="editOrder(donation)">
                      mdi-file-document-edit-outline
                    </v-icon>
                  </div>
                </div>
              </div>
  
              <div v-else-if="activeTab == 1">
                <div class="historique" v-for="(donation, index) in availableDonations" :key="index">
                  <div>{{ donation.donationId }}</div>
                  <div class="name">{{ donation.pharmacyName }}</div>
                  <div class="bis action">{{ formatPrice(donation.totalPrice) }} €</div>
                  <div class="bis action">{{ formatPrice(donation.taxReduction) }} €</div>
                  <div class="bis action"> {{ getOrderSize(donation) }} / {{ donation.minimalDonationCart }}</div>
                  <div class="action">
                    <v-icon small class="mr-2" @click="editOrder(donation)">
                      mdi-file-document-edit-outline
                    </v-icon>
                  </div>
                </div>
              </div>
  
              <div v-else>
                <div v-show="activeTab === 2" class="historique" v-for="(endedDonation, index) in endedDonations" :key="index">
                  <div>{{ endedDonation.donationId }}</div>
                  <div class="name">{{ endedDonation.pharmacyName }}</div>
                  <div class="bis action">{{ formatPrice(endedDonation.totalPrice) }} €</div>
                  <div class="bis action">{{ formatPrice(endedDonation.taxReduction) }} €</div>
                  <div class="bis action"> {{ getOrderSize(endedDonation) }} / {{ endedDonation.minimalDonationCart }}</div>
                  <div class="action">
                    <v-icon small class="mr-2" @click="editOrder(endedDonation)">
                      mdi-file-document-edit-outline
                    </v-icon>
                  </div>
                </div>
              </div>

            
              <v-dialog v-model="dialogInfo"       
              min-width="800"
              max-width="1000">
                <v-card v-if="this.order">
                  <v-card-title class="dialogTitle">
                    Récapitulatif don n° {{ this.order.donationId }}
                  </v-card-title>
            
                  <v-card-text>
                    <v-row v-if="this.order" class="my-3 justify-end">
                      <v-card class="mr-4 infoCard px-8 py-5">
                        <div>
                          <h2 class="text-subtitle-1 pb-5">{{this.order.pharmacyName}}</h2>
                          <p class="text-body-1 text-lowercase">
                            {{ this.order.pharmacyAddress }}
                            <br>{{ this.order.postalCode }}, {{ this.order.city }}
                            <br>{{ this.order.pharmacyMail }}
                          </p>
                        </div>
                      </v-card>
                    </v-row>
            
                    <v-list >
                      <v-divider></v-divider>
                      <template v-for="(item, index) in getProducts()">
                        <v-list-item  :key="item.ean">
                            <v-list-item-content>
                            <v-img
                              :src="item.img != null && item.img !== '' ? item.img
                                : 'https://www.idfmoteurs.com/images/pas-image-disponible.png'
                              "
                              :alt="item.name"
                              contain    
                              width="100"
                              height="100"
                            ></v-img>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title
                                style="white-space: initial"
                              >{{ item.ean }}</v-list-item-title>
                              <v-list-item-subtitle>
                                {{ item.name }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-subtitle>
                                Quantité : {{ item.quantity }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                PU HT achat : {{ item.price }}€
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                Sous-total HT achat : {{ Math.round((item.price * item.quantity) * 100) / 100 }}€
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            
                            <v-list-item-action v-if="order.status == 'inProgress'">
                              <v-btn text @click="editItemQuantity(item)">
                                Corriger le stock
                              </v-btn>
                            </v-list-item-action>
                        </v-list-item>
            
                        <v-divider
                          v-if="index < getProducts().length - 1"
                          :key="index"
                        ></v-divider>
                      </template>
                    </v-list>
            
                    <v-row>
                      <p class="pb-4 ml-4 cardBottom">
                        Quantité min. : {{ getOrderSize(this.order) }} / {{ this.order.minimalDonationCart }}
                        <br>PT HT achat : {{ this.order.totalPrice }} €
                        <br>Réduction fiscale : {{ this.order.taxReduction }} €
                      </p>
                      
                      <v-spacer></v-spacer>
                      <div class="pt-5 pr-5" v-if="this.order.status == 'inProgress'">
                        <v-btn class="px-8 appliquer" small @click="showDialogSendMailToAssos = true" :disabled="getOrderSize(this.order) < this.order.minimalDonationCart">
                          Envoyer une demande
                        </v-btn>
                      </div>
                      <div class="pt-5 pr-5" v-if="this.order.status != 'inProgress' && this.showButtonValidate">
                        <v-btn class="appliquer" @click="showDialogConfirmDonationRetrived = true">
                          Valider
                        </v-btn>
                      </div>
                    </v-row>
                    
                  </v-card-text>
                </v-card>
              </v-dialog>
            
              <v-dialog v-model="showDialogSendMailToAssos" width="400">
                <v-card class=dialogSante>
                  <v-card-text>
                    <div class="title pt-10">
                      <h2 class="text-body-1 black--text">Confirmez-vous la demande de collecte de votre don ?</h2>
                    </div>
                    <div class="title">
                      <p class="text-body-1 black--text">En confirmant, un e-mail sera envoyé aux différentes associations afin de collecter en l'état ou non le don</p>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#0C5C53" text @click="showDialogSendMailToAssos = false">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="appliquer" text @click="sendMailDonationAvailable">Confirmer</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            
            
              <v-dialog v-model="showDialogConfirmDonationRetrived" width="400">
                <v-card class="dialogSante">
                  <v-card-text>
                    <div class="title pt-10">
                      <h2 class="text-body-1 black--text">Confirmez-vous la collecte du don par l'association ?</h2>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#0C5C53" text @click="showDialogConfirmDonationRetrived = false">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="appliquer" text @click="donationHasBeenRetrieve">Confirmer</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              
              <v-dialog v-model="showDialogModifyStock" max-width="500px">
                <v-card class="dialogSante" >
                  <v-card-text>
                    <div class="title pt-10">
                      <h2 class="text-body-1 black--text">Quelle est la quantité à modifier ?</h2>
                    </div>
                  
                    <div>

                      <v-text-field
                          rounded
                          class="rounded-pill champs"
                          v-model.number="product.quantity"
                          placeholder="Quantité"
                          type="number"
                      ></v-text-field>

                    </div>
                    
                    <div class="title pt-4">
                      <p class="text-body-1 black--text">En confirmant, le don sera modifié et l'invendu sera supprimé</p>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#0C5C53" text @click="showDialogModifyStock = false">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="appliquer" text @click="updateQuantity">Confirmer</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            
            </div>

        </div>

        <div class="nav-pages">
          <div>{{ donations.length }} dons en cours</div>
      </div>


  </div>

</div>
      <footer class="footer-style">
          v2.4.2
        </footer>
</div>
</div>
</template>

<script>
  import {
    sendMailDonationAvailable,
    donationHasBeenRetrieve,
    updateQuantity,
    getDonationEnded,
    getDonationInProgress,
    getDonationAvalaible
  } from "./donationService"
import {fr} from 'vuejs-datepicker/dist/locale';
import moment from "moment";
import "firebase/compat/database";
import "animate.css";

export default {
  data: () => ({
    tab: null,
    activeTab : 0,
    tabs: [
        { title: 'Liste'},
        { title: 'En cours'},
        { title: 'Historique'}
    ],
    fr: fr,
    disabled: 0,
    loading: false,
    search: "",
    dialogInfo: false,
    showDialogSendMailToAssos: false,
    showDialogConfirmDonationRetrived : false,
    showDialogModifyStock: false,
    order: {},
    product: 0,
    noMedic: false,
    checkTraceability: false,
    valid: false,
    uidAdmin: process.env.VUE_APP_UID_ADMIN,
    uidTest: process.env.VUE_APP_UID_TEST,
    uidDemo: process.env.VUE_APP_UID_DEMO,
    snackbar: false,
    isUploading: false,
    message: null,
    dialog: false,
    dialogDelete: false,
    dialogDDM: false,
    dialogExpiration: false,
    dialogPourcentage: false,
    dialogDonation: false,
    products: [],
    productsExpiredSoon: [],
    editedIndex: -1,
    donations: [],
    endedDonations: [],
    avalaibleDonations: []
  }),
  mounted() {
    this.getDonationInProgress();
    this.getDonationEnded();
    this.getDonationAvalaible();
  },
  computed: {
    showButtonValidate() {
      if (this.activeTab === 2)
        return false;
      else
        return true;
    },
    filteredDonations() {
      const term = this.search.toLowerCase();
      if (term == '')
        return (this.donations);
      else {
        return this.donations.filter(donation => {
          return donation.pharmacyName.toLowerCase().includes(term);
        })
      }
    }
  },
  methods: {
    sendMailDonationAvailable: sendMailDonationAvailable,
    donationHasBeenRetrieve: donationHasBeenRetrieve,
    updateQuantity: updateQuantity,
    getDonationAvalaible: getDonationAvalaible,
    getDonationEnded: getDonationEnded,
    getDonationInProgress: getDonationInProgress,
    formatPrice(price) {
    const floatPrice = parseFloat(price);
    if (isNaN(floatPrice)) {
        return "Prix invalide";
    }
    return floatPrice.toFixed(2).replace('.', ',');
    },
    checkAdmin() {
      return (this.uidAdmin === localStorage.getItem("uid"));
    },
    getOrderSize(order) {
        let size = 0;

        if(order.products) {
          order.products.forEach((product) => {
            size += product.quantity;
          });
        }

        return size;
      },
    changeTab(index) {
      this.search = "";
      this.activeTab = index;
    },
    formatDate(value) {
        return moment(value).format("DD/MM/YYYY")
    },
    editOrder(item) {
      this.order = item;
      this.dialogInfo = true;
    },
    getProducts() {
      if (this.order != null) {
        return this.order.products;
      } else return [];
    },
    editItemQuantity(item) {
      this.product = item;
      this.showDialogModifyStock = true;
    }
  }
};
</script>

<style scoped>
  
.borderImg {
 border : 1px solid grey;
}

.v-btn{
border-radius:28px!important;
}

.textfield .v-input__slot {
  border-radius: 100px;
  background-color: #FFFFFF;
  border: solid 1px #d3d3d3;
  height: 50px;
}

.textfield .v-label--active {
    background-color: #FFFFFF;
}

.title {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
h3 {
  margin-top: 2rem;
  color: #015c53;
}
a {
  text-decoration: none;
}
.support {
  align-self: center;
  width: 25px;
  height: 25px;
}
.centered-input input {
  text-align: center
}

.top-content {
    padding: 1% 2%;
}

.tab {
    padding: 0.5% 3%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
    margin-right: 2vw;
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 0px 10px;
}

.tab.active {
    background-color: #B5E5DC;
    color: #015c53;
}

.tab.inactive {
    background-color: #FFFFFF;
    color: #3d3c3c;
}

.status {
    border: 2px dashed grey;
    color: grey;
    padding: 0.5% 3%;
    font-size: 20px;
    font-weight: bold;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
    margin-left: 15vw;
}

.dialogTitle {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
}

.infoCard {
  min-height: 18vh !important;
  width: 50%;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: bold !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328) !important;
}

.infoCard p {
  color: rgba(0, 0, 0, 0.5) !important;
}

.ajouter-don {
    padding: 0.5% 3%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
    margin-left: 6vw;
    color: #015c53;
    background-color: #FDD82A;
    font-size: 20px;
    font-weight: bold;
}

.data {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.tableau {
    margin-top: 1.5vh;
    width: 95%;
    height: 90%;
    border: 2px solid rgba(149, 149, 149, 0.501);
    border-radius: 20px;
}

.tableau-title {
    padding: 1% 4%;
    display: flex;
    border: 1px solid rgba(149, 149, 149, 0.501);
    border-radius: 17px;
    width: 100%;
    height: 6vh;
}

.tableau-title div {
    font-weight: bold;
    color: grey;
    font-size: 20px;
}

.custom-dialog {
    border-radius: 30px !important;
    /* background-color: pink !important; */
}

.action {
  display: flex;
  justify-content: center
}

.cardBottom {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
  font-size: 16px;
}

.v-list-item__action .v-btn--text {
  text-transform: none !important;
  color : rgba(1, 92, 83, 1) !important;
  font-size: 16px;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(253, 216, 42, 0.5) !important;
  color:rgba(1, 92, 83, 0.5) !important;
}

.champs {
border: 1px solid grey;
padding: 2% 2% 2% 2%;
flex: none;
margin-bottom: 3vh;
box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
}

.dialogSante p, .dialogSante h2 {
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: bold;
}

.appliquer {
color: #015c53 !important;
background-color: #FDD82A !important;
margin:1vh 1vh 1vh 1vh;
text-transform: none !important;
font-weight: bold !important;
font-size: 16px !important;
border: none !important;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

@media screen and (max-width: 1350px) {
  .search {
    width: 18vw;
    top: 22px;
  }

  .tableau-title div {
    font-size: 10px;
  }

  .invendus {
    font-size: 10px;
  }

  .nav-pages * {
    font-size: 10px;
  }

  .data {
    height: 75% !important;
  }

  .tableau th {
    font-size: 14px;
  }

  .select-nb {
    width: 30%;
  }

  .historique {
    font-size: 10px;
  }

  .tab, .status {
    font-size: 12px;
    height: 5vh;
  }
}

</style>



