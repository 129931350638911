<template>
   <div class="container-bg">
        <div>
            <div class="container-content">
                <div v-if="loading" class="loading">
                    <div class="loader"></div>
                </div>
                <div v-else class="listeDons">
                    <div class="left">
                        <div v-if="firstSevenProducts.length > 0" class="productsList">
                            <h2>Top produits recherchés</h2>
                            <div class="table">
                                <div class="thead tableRow">
                                    <div class="index">Classement</div>
                                    <div class="name">Libellé</div>
                                    <div class="brand">Marque</div>
                                </div>
                                <div class="tbody">
                                    <div class="tableRow product" v-for="(product, index) in firstSevenProducts" :key="index" @click="switchIndex(index)">
                                        <div class="index">{{ index + 1 }}</div>
                                        <div class="name">{{ product.name }}</div>
                                        <div class="brand">{{ product.brand }}</div>
                                    </div>
                                </div>
                            </div>
                            <div  class="detailProduct">
                                <div class="img">
                                    <v-img
                                    :src="firstSevenProducts[this.index].img"
                                    class="rounded-circle borderImg"
                                    width="100"
                                    height="100"
                                    contain
                                    ></v-img>
                                </div>
                                <div class="text">
                                    <div class="description">
                                        {{ firstSevenProducts[this.index].description }}
                                    </div>
                                    <div @click="sendMailInfos" class="infoProduct">
                                        + D'INFOS
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="noProduct">
                            <div>Le ciblage n'est pas disponible <br>dans cette zone de chalandise.</div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="caracteristic">
                            <div  :style="{ visibility: checkAdmin ? 'visible' : 'hidden' }" class="numberPharma">
                                <v-text-field
                                class="chooseNumber"
                                rounded
                                v-model="pharmaNumber"
                                label="Numéro de la pharmacie"
                                append-outer-icon="mdi-check"
                                @click:append-outer="reloadData">
                                </v-text-field>
                            </div>
                            <div class="selectZone">
                                Zone de chalandise
                                <v-btn class="btnZone"
                                @click="showZone = true"
                                >
                                    <i class="mdi mdi-plus"></i>
                                </v-btn>
                                <div class="filterZone" v-show="showZone">
                                    <div class="filterTitle">Filtrer par distance</div>
                                    <v-text-field
                                    rounded
                                    v-model="selectedZone"
                                    label="Choisissez une zone"
                                    :readonly="true"
                                    append-outer-icon="mdi-menu-down"
                                    prepend-inner-icon="mdi-menu-up"
                                    @click:append-outer="decrement"
                                    @click:prepend-inner="increment"
                                    ></v-text-field>
                                    <div class="apply rounded-pill" @click="applyZone">Appliquer</div>
                                </div>
                            </div>
                        </div>
                        <h2>Ciblage</h2>
                        <div class="infoUsers">
                            <div class="nbUsers">
                                <p>Utilisateurs</p>
                                <div class="data">{{ nbUsers }}</div>
                            </div>
                            <div class="ageUsers">
                                <p>Age moyen</p>
                                <div v-if="age" class="data">{{ age }} ans</div>
                                <div v-else class="data">Non disponible</div>
                            </div>
                        </div>
                        <h2>Cartographie</h2>
                        <div id="map"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { productsSearchMobileSideCollection, usersCollection, usersMobileSideCollection, pharmacies, productsGlossaryCollection, brandsCollection } from "../../main";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/images/marker-shadow.png';

export default {

    data() {
        return {
            map: null,
            products: [],
            productSearch: [],
            firstSevenProducts: [],
            loading: true,
            nbUsers: 0,
            users: [],
            usersZone: [],
            markersLayer: null,
            showZone: false,
            sumAge: 0,
            nbAge: 0,
            index: 0,
            pharma: {lat: 0, long: 0},
            pharmaNumber: "",
            addressPharma: "",
            zoom: 14,
            zone: 0.5,
            zoneOptions: ['< 500m', '< 1km', '< 2km', '< 5km'],
            selectedZone: '< 500m',
            age: 0,
            radius: 1000,
            user: firebase.auth().currentUser,
            filteredUsers: [],
            mapCenter: {lat: 0, lng: 0},
            circleOptions: {
                fillColor: '#000000',
                fillOpacity: 0.1,
                strokeColor: '#000000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
            },
        }
    },

    computed: {
    checkAdmin() {
            return process.env.VUE_APP_UID_ADMIN === localStorage.getItem("uid");
    },
  },

  async mounted() {
    try {
      await this.initializeUser();

      await Promise.all([this.loadProducts(), this.loadUsers()]);

      this.loading = false;

      await this.$nextTick();

      this.initializeMap();

      await this.addMarkers();
      this.firstSevenProducts = this.productsZone();

    } catch (error) {
      console.error('Erreur lors de la mise à jour des utilisateurs: ', error);
      this.loading = false;
    }
  },

    methods: {
        async initializeUser() {
            const userAuth = firebase.auth().currentUser.uid;
            const res = await usersCollection.doc(userAuth).get();
            const data = res.data();
            this.pharma = {
                name: data.pharmacyName,
                address: data.address,
                city: data.city,
                postalCode: data.postalCode,
                lat: data.geopos.lat,
                long: data.geopos.long,
                mail: data.mail
            };
        },
        initializeMap() {
            [Math.round(this.pharma.lat * 1000) / 1000, Math.round(this.pharma.long * 1000) / 1000]
            const lat = Math.round(this.pharma.lat * 1000) / 1000;
            const long = Math.round(this.pharma.long * 1000) / 1000;
            this.map = L.map('map').setView([lat, long], this.zoom);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy;'
            }).addTo(this.map);
            this.markersLayer = L.layerGroup().addTo(this.map);
        },
        async loadProducts() {
            const querySnapshot = await productsSearchMobileSideCollection
                .where("statut", "==", "completed")
                .get();
            
            for (const doc of querySnapshot.docs) {
                let data = doc.data();
                let img = await this.imgProduct(data.ean);
                let product = {
                    name: data.name,
                    brand: data.brand,
                    description: data.description,
                    ean: data.ean,
                    id: data.id,
                    created_at: data.created_at,
                    img: img
                };
                this.products.push(product);
            }
        },
        async loadUsers() {
            const querySnapshot = await usersMobileSideCollection.get();
            
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                let distance = this.haversineDistance(this.pharma.lat, this.pharma.long, data.latitude, data.longitude);
                
                if (distance <= 5) {
                let user = {
                    age: data.age,
                    id: data.id,
                    latitude: data.latitude,
                    longitude: data.longitude
                };
                this.users.push(user);
                }
            });
        },
        async addMarkers() {
            this.nbUsers = 0;
            this.sumAge = 0;
            this.nbAge = 0;

            this.markersLayer.clearLayers();
            const DefaultIcon = L.icon({
                iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
                shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            });
            L.Marker.prototype.options.icon = DefaultIcon;
            for (let user of this.users) {
                if (user.latitude && user.longitude) {
                    let distance = this.haversineDistance(this.pharma.lat, this.pharma.long, user.latitude, user.longitude);
                    if (distance < this.zone) {
                        this.usersZone.push(user);
                        let marker = L.marker([user.latitude, user.longitude]);
                        this.markersLayer.addLayer(marker);
                        if (user.age !== 0) {
                            this.sumAge += user.age;
                            this.nbAge++;
                        }
                        this.nbUsers++;
                    }
                }
            }
            this.age = Math.round(this.sumAge / this.nbAge);
        },
        haversineDistance(lat1, lon1, lat2, lon2) {
            const toRad = x => (x * Math.PI) / 180;
            const R = 6371; 

            const dLat = toRad(lat2 - lat1);
            const dLon = toRad(lon2 - lon1);

            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            return R * c;
        },
        switchIndex(index) {
            this.index = index;
        },
        increment() {
            const currentIndex = this.zoneOptions.indexOf(this.selectedZone);
            if (currentIndex < this.zoneOptions.length - 1) {
                this.selectedZone = this.zoneOptions[currentIndex + 1];
            }
        },
        decrement() {
            const currentIndex = this.zoneOptions.indexOf(this.selectedZone);
            if (currentIndex > 0) {
                this.selectedZone = this.zoneOptions[currentIndex - 1];
            }
        },
        applyZone() {
            this.usersZone = [];
            const zoneMapping = {
                '< 500m': 0.5,
                '< 1km': 1,
                '< 2km': 2,
                '< 5km': 5
            };
            this.zone = zoneMapping[this.selectedZone];
            if (this.zone > 2)
                this.zoom = 12;
            else if (this.zone > 1)
                this.zoom = 13;
            else
                this.zoom = 14;
            this.addMarkers();
            this.firstSevenProducts = this.productsZone();
            this.map.setZoom(this.zoom);
            this.showZone = false;
        },
        async reloadData() {
            this.selectedZone = '< 500m';
            this.showZone = false;
            this.zoom = 14;

            const pharma = await pharmacies.where("phone", "==", this.pharmaNumber).get();
            if (pharma.empty) {
                this.$toast.error("Il n'y a pas de pharmacie associée à ce numéro de téléphone", {
                    position: 'bottom-center',
                    timeout: 5000,
                });
                this.pharmaNumber = "";
                return;
            }

            pharma.forEach(doc => {
                const data = doc.data();
                this.pharma = {
                    name: data.name,
                    address: data.address,
                    city: data.city,
                    postalCode: data.postalCode,
                    lat: data.geopos.lat,
                    long: data.geopos.long
                };
            });

            this.product = [];
            this.users == [];
            this.usersZone = [];
            await Promise.all([this.loadProducts(), this.loadUsers()]);

            await this.$nextTick();

            if (this.map) {
                this.map.remove();
            }
            
            this.initializeMap();

            await this.addMarkers();
            this.firstSevenProducts = this.productsZone();
            this.pharmaNumber = "";
            const message = `
                ${this.pharma.name}
                ${this.pharma.address}, ${this.pharma.postalCode} ${this.pharma.city}
            `;
            this.$toast.success(message , {
                    position: 'bottom-center',
                    timeout: 5000,
                    dangerouslyUseHTMLString: true,
            });
            
        },
        productsZone() {
            let productZone = [];
            if (this.products.length > 1) {
                this.products.forEach((product) => {
                    if (this.usersZone.some((user) => user.id == product.id)) {
                        productZone.push(product);
                    }
                });
            }


            let eanCount = {};
            productZone.forEach((product) => {
                if (product.ean) {
                    if (eanCount[product.ean]) {
                        eanCount[product.ean]++;
                    } else {
                        eanCount[product.ean] = 1;
                    }
                }
            });

            let uniqueEANs = [];
            productZone = productZone.filter((product) => {
                if (!uniqueEANs.includes(product.ean)) {
                    uniqueEANs.push(product.ean);
                    return true;
                }
                return false;
            });

            productZone.sort((a, b) => {
                let countA = eanCount[a.ean] || 0;
                let countB = eanCount[b.ean] || 0;
                
                if (countA === countB) {
                    let dateA = a.created_at;
                    let dateB = b.created_at;
                    return dateB - dateA; 
                }
                
                return countB - countA;
            });


            return productZone;
        },
        async sendMailInfos() {
            const product = this.firstSevenProducts[this.index];
            console.log(product);
            const brand = await brandsCollection.where("brand", "==", product.brand).get();
            if (brand.empty) {
                this.$toast.error("Votre demande d'informations n'a pas abouti, n'hésitez pas à nous contacter directement.", {
                    position: 'bottom-center',
                    timeout: 5000,
                });
                return;
            }
            const data = brand.docs[0].data();
            console.log(data.mail);
            const dataToSend = {
                sendTo: data.mail,
                ean: product.ean,
                name: product.name,
                brand: product.brand,
                pharma: this.pharma.name,
                address: this.pharma.address,
                postalCode: this.pharma.postalCode,
                city: this.pharma.city,
                mail: this.pharma.mail
            };
            try {
                // POUR LA PRODUCTION
                const reponse = await fetch('https://cloud.keabot.fr:3000/targeting/infos', {
                // POUR LE TEST EN LOCAL
                //const reponse = await fetch('http://localhost:3000/targeting/infos', {
                        method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataToSend),
                    credentials: 'include',
                })
                console.log(reponse);
                this.$toast.success("Votre demande d'informations a bien été prise en compte. Nous vous recontacterons au plus vite.", {
                    position: 'bottom-center',
                    timeout: 5000,
                });
            } catch (error) {
                this.$toast.error("Votre demande d'informations n'a pas abouti, n'hésitez pas à nous contacter directement.", {
                    position: 'bottom-center',
                    timeout: 5000,
                });
            }
        },
        async imgProduct(ean) {
            const eanString = String(ean);
            const imgProduct = await productsGlossaryCollection.where("ean", "==", eanString).get();
            if (!imgProduct.empty) {
                const data = imgProduct.docs[0].data();
                return data.img;
            }
            return 'https://www.idfmoteurs.com/images/pas-image-disponible.png';
        }

    }
}
</script>

<style scoped>

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 92vh !important;
}

.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #17603b; 
  border-radius: 50%;
  height: 120px;
  width: 120px;
  animation: spin 2s linear infinite;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#map {
    border-radius: 20px;
    width: 95%;
    height: 50%;
    z-index: 10;
}

h2 {
    color: rgba(1, 92, 83, 1);
    margin-bottom: 2vh;
    font-size: 18px;
}

.content {
    height: 92vh;

}

.listeDons {
    display: flex;
    gap: 5vw;
    flex-direction: row;
}

.left, .right {
    width: 45% !important;
}

.left {
    padding: 4vh 2vw;
}

.noProduct {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #015c53;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
}

.productsList {
    display: flex;
    flex-direction: column;
}

.table {
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-bottom: 2vh;

}

.tableRow {
    display: flex;
    height: 5.5vh;
    padding-left: 2vw;
    align-items: center;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 900;
    line-height: 14.06px;
    text-align: left;
    color: rgba(0, 0, 0, 0.5);
}

.tbody {
    max-height: 40vh;
    overflow: scroll;
}

.thead {
    border-radius: 20px;
    border: 1px solid rgba(196, 196, 196, 1);
}

.tableRow.product {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.tableRow.product:last-child {
    border-bottom: none;
}

.index {
    width: 19%;
}

.brand {
    width: 20%
}

.name {
    width: 62%;
}

.product {
    cursor: pointer;
}

.product:hover {
    color: rgba(1, 92, 83, 1);
}

.img {
    height: 100px;
    width: 100px;
  margin: 3vh 0;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  }

.detailProduct {
    display: flex;
    /* position: absolute; */
    gap: 2vw;
    width: 100%;
    height: 100%;
    padding: 2%;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    /* width: 100%; */
}

.description {
    color: rgba(0, 0, 0, 0.66);
    font-weight: 700;
    font-size: 10px;
    max-height: 100px !important;
    overflow: scroll;
}

.infoProduct {
    width: 100px;
    height: 20px;
    border-radius: 20px;
    background: rgba(181, 229, 220, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(1, 92, 83, 1);
    font-size: 10px;
    font-weight: bold;
    /* position:fixed; */
    margin-top: 5px;
    margin-left: 10vw;
    /* bottom: 100px; */

    cursor: pointer;
}

.btnZone {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: #015c53 !important;
  font-size: 20px !important;
}

.caracteristic {
    display: flex;
    gap: 2vw;
    margin: 2vh 0;
    width: 100%;
}

.selectZone, .numberPharma {
    width: 45%;
    height: 4vh;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 0 0.5vw;
    color: rgba(0, 0, 0, 0.5);
    font-family: Roboto;
    font-size: 12px !important;
    font-weight: 700;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.chooseNumber {
    font-size: 8px;
    padding: 0 !important;
}

.selectZone {
    border: 2px dashed rgba(0, 0, 0, 0.37);
    justify-content: space-between;
    position: relative !important;
}

.filterZone {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    cursor: pointer;
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    padding: 8%;
    border: 1px solid rgba(196, 196, 196, 1);
    background-color: white;
    border-radius: 20px;
}

.apply {
    background: rgba(253, 216, 42, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: rgba(1, 92, 83, 1);
    font-size: 20px;
    display: flex;
    justify-content: center;
}

.infoUsers {
    display: flex;
    gap: 2vw;
    margin-bottom: 2vh;
}

.nbUsers, .ageUsers {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 45%;
    height: 84px;
    border-radius: 20px;
}

.nbUsers p, .ageUsers p {
    padding: 10px 0 0 10px;
    margin: 0;
    color: rgba(0, 0, 0, 0.66);
}

.data {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 39.84px;
    text-align: center;
    color: rgba(1, 92, 83, 1);
}

</style>