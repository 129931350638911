<template lang="html">
    <div v-show="isActive">
      <slot></slot>
    </div>
  </template>
  
  <script>
    export default {
      props: {
        title: {
          type: String,
          default: 'Tab'
        }
      },
      data () {
        return {
          isActive: true
        }
      }
    }
  </script>

  <style scoped>
    .tabs__content {
        background-color: red;
        width: 100%;
    }
  </style>