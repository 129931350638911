<template>
  <div class="container-bg">
    <div>
      <div class="container-content">
        <div class="payment">
          <h1>Paiement réussi</h1>
          <button class="rounded-pill" @click="redirect">Accéder à la fonctionnalité</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import firebase from "firebase/compat/app";

export default {
  data() {
    return {
      sessionId: null,
      uid: null,
    };
  },

  async mounted() {
    await this.checkAuthState();
    this.sessionId = this.$route.params.session_id;
    if (this.sessionId) {
      await this.verifySessionAndExtendSubscription();
    }
  },

  methods: {
    redirect() {
      this.$router.push('/targeting');
    },

    async checkAuthState() {
      await new Promise(resolve => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          if (user) {
            this.uid = user.uid;
          }
          unsubscribe();
          resolve(user);
        });
      });
    },

    async verifySessionAndExtendSubscription() {
      try {
        //const response = await axios.post('http://localhost:3000/targeting/verify-session', { sessionId: this.sessionId });
        const response = await axios.post('https://cloud.keabot.fr:3000/targeting/verify-session', { sessionId: this.sessionId });

        if (response.data.valid && this.uid) {
          const subscriptionExpiry = new Date();
          subscriptionExpiry.setFullYear(subscriptionExpiry.getFullYear() + 1);

          await axios.post('https://cloud.keabot.fr:3000/targeting/extend-subscription', {
          //await axios.post('http://localhost:3000/targeting/extend-subscription', {
            uid: this.uid,
            subscriptionExpiry: subscriptionExpiry.toISOString(),
          });

          console.log('Abonnement prolongé avec succès');
        } else {
          console.error('Session invalide ou utilisateur non authentifié');
        }
      } catch (error) {
        console.error('Erreur lors de la vérification de la session:', error);
      }
    },
  },
};
</script>


<style scoped>

.payment {
    height: 92vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

button {
  padding: 1% 3%;
  margin-top: 2vh;
  height: 5vh !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  color: #015c53;
  background-color: #FDD82A;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center !important;
}


</style>