<template>
<div class="container-bg">
<div>
<div class="container-content">
  <div class="content">
    <v-row class="mt-10" align="center" justify="center">
      <div class="image-upload">
        <label for="file-input">
          <div v-if="!loading">
            <v-img
              v-if="formInfoFirebase.imgProfil"
              :src="formInfoFirebase.imgProfil"
              max-width="300"
              class="imag"
            />
            <div v-else class="btnDesign">Photo d'équipe</div>
          </div>
          <div v-else>
            <v-progress-circular
              indeterminate
              color="green"
            ></v-progress-circular>
          </div>
        </label>
        <input id="file-input" type="file" @change="onFileInput($event)" />
      </div>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="6" md="7" align="center" class="text-center mt-10">
        <h2>Mes informations personnelles</h2>
        <v-form ref="formInfoFirebase" v-model="valid" lazy-validation>
          {{ formInfoFirebase.mail }}

          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="formInfoFirebase.firstName"
                :rules="neededRules"
                label="Prénom"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="formInfoFirebase.lastName"
                :rules="neededRules"
                label="Nom"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="formInfoFirebase.pharmacyName"
                :rules="neededRules"
                label="Nom de la pharmacie"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="formInfoFirebase.siret"
                label="SIRET"
                @keypress="isNumber($event)"
                v-on:keypress="onKeyPress"
                id="siretInput"
                :rules="siretRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="formInfoFirebase.tva"
                v-on:keypress="onKeyPress"
                id="tvaInput"
                :rules="neededRules"
                label="N° TVA"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="formInfoFirebase.legalStatus"
                label="Statut juridique"
                id="siretInput"
                :items="legalStatusOptions"
                class="legalStatuts-icon"
                required
              ></v-select>
            </v-col>
          </v-row>
         <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="formInfoFirebase.address"
                label="Adresse"
                required
                :rules="neededRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="formInfoFirebase.denominationName"
                label="Dénomination sociale"
                :rules="neededRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="formInfoFirebase.postalCode"
                :rules="postalCodeRules"
                @keypress="isNumber($event)"
                v-on:keypress="onKeyPress"
                id="codePostalInput"
                label="Code postal"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="formInfoFirebase.city"
                :rules="neededRules"
                label="Ville"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="formInfoFirebase.phone"
                :rules="phoneRules"
                @keypress="isNumber($event)"
                v-on:keypress="onKeyPress"
                id="phoneInput"
                label="Téléphone"
                type="tel"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="formInfoFirebase.cip"
                :rules="cipRules"
                @keypress="isNumber($event)"
                label="CIP"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7">
              <v-text-field
                v-model="formInfoFirebase.groupement"
                label="Groupement"
                :rules="neededRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="formInfoFirebase.lgo"
                label="Nom du LGO"
                :rules="neededRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          Relevé d'Identité Bancaire :
          <v-row>
            <v-col cols="7">
              <v-text-field
                v-model="formInfoFirebase.iban"
                label="IBAN"
                v-on:keypress="onKeyPress"
                d="ibanInput"
                required
                :rules="ibanRules"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="formInfoFirebase.bic"
                label="BIC"
                v-on:keypress="onKeyPress"
                id="bicInput"
                required
                :rules="bicRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <br>
          <br>
          <v-btn color="#015C53" dark @click="submitModification">
            Modifier
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <div class="error animate__animated animate__fadeIn" v-if="error">
      {{ error }}
    </div>
    <v-snackbar v-model="snackbar">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</div>
</div>
</div>
</template>

<script>
import { usersCollection, storageRef } from "../../main";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { isNumber } from "../arrayData/functionArrayData";

export default {
  data() {
    return {
      loading: false,
      text: "",
      user: firebase.auth().currentUser,
      valid: false,
      snackbar: false,
      formInfoFirebase: {
        mail: "",
        firstName: "",
        lastName: "",
        pharmacyName: "",
        siret: "",
        address: "",
        postalCode: "",
        city: "",
        phone: "",
        cip: "",
        groupement: "",
        lgo: "",
        imgProfil: "",
        denominationName: "",
        tva: "",
        legalStatus: "",
      },
      legalStatusOptions: ['SELARL', 'SELAS', 'SNC','SARL','SERL','SAS','SASU','SA','EI','SPFPL SARL','SPFPL SAS','SELAFA','EURL','GIE', 'GCS', 'SCS', 'Exploitation en nom propre','Mutuelle','Autres'
      ],
      neededRules: [(v) => !!v || "Ce champ est requis"],
      siretRules: [
        (v) => !!v || "Ce champ est requis",
        (v) =>
          (v && v.length == 14) || "Le SIRET doit être composé de 14 chiffres",
        (v) => /^\d+$/.test(v) || "Seul les chiffres sont autorisés",
      ],
      tvaRules: [
        (v) => !!v || "Ce champ est obligatoire",
        (v) => (v && v.length === 13) || "Le numéro de TVA doit être composé de 13 caractères",
        (v) => /^[0-9a-zA-Z]+$/.test(v) || "Seuls les chiffres et les lettres sont autorisés",
      ],
      postalCodeRules: [
        (v) => !!v || "Ce champ est requis",
        (v) =>
          (v && v.length == 5) ||
          "Le code postal doit être composé de 5 chiffres",
        (v) => /^\d+$/.test(v) || "Seul les chiffres sont autorisés",
      ],
      phoneRules: [
        (v) => !!v || "Ce champ est requis",
        (v) =>
          (v && v.length == 10) ||
          "Le télephone doit être composé de 10 chiffres",
        (v) => /^\d+$/.test(v) || "Seul les chiffres sont autorisés",
      ],
      cipRules: [
        (v) => !!v || "Ce champ est requis",
        (v) => (v && v.length >= 5) || "Le CIP doit faire minimum 5 caractères",
        (v) => /^\d+$/.test(v) || "Seul les chiffres sont autorisés",
      ],
      ibanRules: [
        (v) => !!v || "Ce champ est requis",
        (v) =>
          (v && v.length <= 34 && v.length >= 14) ||
          "L'IBAN comprend 14 à 34 caractères",
      ],
      bicRules: [
        (v) => !!v || "Ce champ est requis",
        (v) =>
          (v && v.length <= 11 && v.length >= 8) ||
          "Le BIC comprend 8 à 11 caractères",
      ],
      error: null,
    };
  },
  mounted() {
    this.getImg();
    usersCollection
      .doc(this.user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.formInfoFirebase = Object.assign(
            this.formInfoFirebase,
            doc.data()
          );
        } else {
          console.log("Il n'existe pas de données pour cet utilisateur");
        }
      });
  },
  methods: {
    isNumber: isNumber,
    onKeyPress(event) {
      const keyCode = event.keyCode || event.which;
      const keyValue = String.fromCharCode(keyCode);
      const targetValue = event.target.value;
      if (event.target.id === 'siretInput') {
        if (/^\d$/.test(keyValue)) {
          const siret = targetValue + keyValue;
          if (siret.length > 14) {
            event.preventDefault();
          }
        } else {
          event.preventDefault();
        }
      } else if (event.target.id === 'tvaInput') {
        if (targetValue.length > 12) {
          event.preventDefault();
        }
      } else if (event.target.id === 'codePostalInput') {
        if (/^\d$/.test(keyValue)) {
          const codePostal = targetValue + keyValue;
          if (codePostal.length > 5) {
            event.preventDefault();
          }
        } else {
          event.preventDefault();
        }
      } else if (event.target.id === 'phoneInput') {
        if (/^\d$/.test(keyValue)) {
          const phone = targetValue + keyValue;
          if (phone.length > 10) {
            event.preventDefault();
          }
        } else {
          event.preventDefault();
        }
      } else if (event.target.id === 'ibanInput') {
        if (/^\d$/.test(keyValue)) {
          const iban = targetValue + keyValue;
          if (iban.length > 34) {
            event.preventDefault();
          }
        } else {
          event.preventDefault();
        }
      } else if (event.target.id === 'bicInput') {
        if (/^\d$/.test(keyValue)) {
          const bic = targetValue + keyValue;
          if (bic.length > 11) {
            event.preventDefault();
          }
        } else {
          event.preventDefault();
        }
      }
    },
    getImg() {
      storageRef
        .child(`profile/${this.user.uid}/imgProfil`)
        .getDownloadURL()
        .then((url) => {
          if (url) {
            this.formInfoFirebase.imgProfil = url;
          } else {
            return console.log("pas d'image");
          }
        });
    },
    async onFileInput(event) {
      this.loading = true;
      let file = new File([event.target.files[0]], event.target.files[0].name);
      let url = "";
      const metadata = {
        contentType: "image/jpeg",
      };
      await storageRef
        .child(`profile/${this.user.uid}/imgProfil`)
        .put(file, metadata);
      url = await storageRef
        .child(`profile/${this.user.uid}/imgProfil`)
        .getDownloadURL();

      this.formInfoFirebase["imgProfil"] = url;
      this.loading = false;
    },
    async submitModification() {
      this.loading = true;
      if (this.$refs.formInfoFirebase.validate()) {
        try {
          await usersCollection
            .doc(this.user.uid)
            .update({...this.formInfoFirebase, timestamp: firebase.firestore.FieldValue.serverTimestamp()})
            .then(() => {
              this.error = null;
              this.snackbar = true;
              this.text = "Modification effectuée avec succès";
              setTimeout(() => {
                this.snackbar = false;
                this.$router.push("/home");
              }, 2000);
            })
            .catch((error) => {
              this.error = error.message;
            });
        } catch (error) {
          this.error = error.message;
        }
      } else {
        this.error = "Veuillez remplir tous les champs";
        return;
      }
      this.loading = false;
    },
    async isValidAddress() {
      if(this.pharmacieInfo.address && this.pharmacieInfo.postalCode && this.pharmacieInfo.city) {
        let query = this.pharmacieInfo.address + " " + this.pharmacieInfo.postalCode + " " + this.pharmacieInfo.city;
        await fetch(`https://api-adresse.data.gouv.fr/search/?q=${query}`)
          .then(result => result.json())
          .then(result => {
              if(result.features && result.features.length > 0) { 
                if(result.features[0].properties.postcode === this.pharmacieInfo.postalCode) {
                  this.errorPostalCode = [];
                } else {
                  this.errorPostalCode = ["Veuillez saisir une adresse valide"];
                }
                if(this.removeDiatrics(result.features[0].properties.name.toLowerCase()) === this.removeDiatrics(this.pharmacieInfo.address.toLowerCase())) {
                  this.errorAddress = [];
                } else {
                  this.errorAddress = ["Veuillez saisir une adresse valide"];
                }
                if(this.removeDiatrics(result.features[0].properties.city.toLowerCase()) === this.removeDiatrics(this.pharmacieInfo.city.toLowerCase())) {
                  this.errorCity = [];
                } else {
                  this.errorCity = ["Veuillez saisir une adresse valide"];
                }
              } else {
                  this.errorCity = ["Veuillez saisir une adresse valide"];
                  this.errorAddress = ["Veuillez saisir une adresse valide"];
                  this.errorPostalCode = ["Veuillez saisir une adresse valide"];
              }
              
          }, error => {
              console.error(error);
          });
      }
    },
  }, 
};
</script>

<style scoped>

.container-content {
  height: 160vh;
}

.legalStatuts-icon >>> .v-icon {
  font-size: 24px;
  margin-top: 6px;
  animation: none;
}
.imag {
  border-radius: 50px;
}
.btnDesign {
  background-color: #015c53;
  color: white;
  width: 100%;
  border-radius: 5px;
  border: none;
  font-size: 1.2rem;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.image-upload > input {
  display: none;
}
p {
  text-align: center;
}
h2 {
  color: #015c53;
}
.error {
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  -webkit-box-shadow: 0 0 10px rgb(255, 0, 0);
  -moz-box-shadow: 0 0 10px rgb(255, 0, 0);
}

@media screen and (max-width: 1300px) {

.infos {
    padding-top: 0vh;
}

.data {
    font-size: 20px;
}

li {
    font-size: 14px;
    padding: 0.2vh 0;
}

h2 {
    font-size: 16px;
}

.products {
    font-size: 12px
}
}

</style>