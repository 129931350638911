<template>
  <div class="login-container">
      <router-link class="logo" to="/home">
      <img src="../../assets/logo.png" class="img-logo" />
      </router-link>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="6" md="7" align="center" class="text-center">
        <div class="title">Inscription</div>
        <v-form ref="registerForm" v-model="valid" lazy-validation>
          <v-text-field
            rounded
            class="mb-6"
            v-model="registerForm.email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
          <v-row>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="registerForm.password"
                :rules="passwordRules"
                :type="registerShow ? 'text' : 'password'"
                :append-icon="registerShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="registerShow = !registerShow"
                label="Mot de passe"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="registerForm.confirmPassword"
                :type="registerShow ? 'text' : 'password'"
                :append-icon="registerShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="registerShow = !registerShow"
                label="Confirmation du mot de passe"
                required
                :rules="confirmPasswordRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="pharmacieInfo.firstName"
                label="Prénom"
                required
                :rules="neededRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="pharmacieInfo.lastName"
                label="Nom"
                required
                :rules="neededRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="pharmacieInfo.denominationName"
                label="Dénomination sociale de l'entreprise"
                required
                :rules="neededRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="pharmacieInfo.siret"
                label="N° SIRET"
                @keypress="isNumber($event)"
                required
                :rules="siretRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="pharmacieInfo.pharmacyName"
                label="Nom commercial de la boutique"
                :rules="neededRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="pharmacieInfo.address"
                label="Adresse"
                @keypress="isValidAddress(true)"
                required
                :rules="neededRules"
                :error-messages="errorAddress"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="pharmacieInfo.postalCode"
                label="Code postal"
                @keypress="isNumber($event), isValidAddress(true)"
                required
                :rules="postalCodeRules"
                :error-messages="errorPostalCode"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="pharmacieInfo.city"
                label="Ville"
                @keypress="isValidAddress(true)"
                required
                :rules="neededRules"
                :error-messages="errorCity"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="pharmacieInfo.phone"
                label="Téléphone"
                type="tel"
                @keypress="isNumber($event)"
                required
                :rules="phoneRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="title">
            Relevé d'Identité Bancaire
          </div>
          <v-row>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="pharmacieInfo.iban"
                label="IBAN"
                required
                :rules="ibanRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                rounded
                v-model="pharmacieInfo.bic"
                label="BIC"
                required
                :rules="bicRules"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-checkbox
          class="mt-12"
            v-model="checkTraceability"
            label="Je m'engage à garantir la tracabilité de chaque produit référencé sur cette plateforme."
            type="checkbox"
            :rules="checkboxRules"
            required
          ></v-checkbox>

          <v-checkbox class="mt-0 mb-5" v-model="checkCGU" required :rules="checkboxRules"
            ><template v-slot:label>
              <div>
                J'accepte les 
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="https://partenaire.keabot.fr/cgu/"
                      @click.stop
                      v-on="on"
                    >
                      Conditions Générales d'Utilisation
                    </a>
                  </template>
                  Ouvrir dans un nouvel onglet
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
          <template>
            <v-row v-if="dialog == true">
              <Dialog dialog :connect="connect" />
            </v-row>
            <v-btn class="inscription" @click="submitRegister">
              S'inscrire
            </v-btn>
          </template>
        </v-form>
      </v-col>
    </v-row>
    <div class="error animate__animated animate__fadeIn" v-if="error">
      {{ error }}
    </div>
  </v-container>
  <footer class="footer-style">
          v2.4.2
          <span class="footer-separator">|</span>
          <span ><a target="_blank" class="legalNotices-style" :href="legalNotices"> Mentions légales </a></span>
          <span class="footer-separator">|</span>
          <span><a target="_blank" class="privacyPolicy-style" :href="privacyPolicy"> Politique de confidentialité </a></span>
        </footer>

  </div>
</template>


<script>
import Dialog from "../dialog.vue";
import firebase from "firebase/compat/app";
import { isNumber } from "../arrayData/functionArrayData";
import "animate.css";
import { usersCollection } from "../../main";
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      errorCity: [],
      errorAddress: [],
      errorPostalCode: [],
      dialog: false,
      valid: false,
      registerShow: false,
      checkCGU: false,
      loggedIn: false,
      legalNotices: "https://partenaire.keabot.fr/mentions-legales/",
      privacyPolicy: "https://partenaire.keabot.fr/politique-de-confidentialite/",
      registerForm: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      pharmacieInfo: {
        mail: "",
        address: "",
        city: "",
        postalCode: "",
        phone: "",
        firstName: "",
        lastName: "",
        cip: "",
        pharmacyName: "",
        groupement: "",
        lgo: "",
        siret: "",
        denominationName: "",
      },
      loginForm: {
        email: "",
        password: "",
      },
      emailRules: [
        (v) => !!v || "E-mail requis",
        (v) => /.+@.+/.test(v) || "E-mail invalide",
        (v) => v.toLowerCase() === v || "E-mail doit être en minuscule",
      ],
      passwordRules: [
        (v) => !!v || "Mot de passe requis",
        (v) =>
          v.length >= 6 || "Mot de passe doit contenir au moins 6 caractères",
      ],
      confirmPasswordRules: [
        (v) => !!v || "Mot de passe requis",
        () =>
          this.registerForm.password === this.registerForm.confirmPassword ||
          "Les mots de passe doivent être identiques",
      ],
      neededRules: [(v) => !!v || "Ce champ est requis"],
      siretRules: [
        (v) => !!v || "Ce champ est requis",
        (v) =>
          (v && v.length == 14) || "Le SIRET doit être composé de 14 chiffres",
        (v) => /^\d+$/.test(v) || "Seul les chiffres sont autorisés",
      ],
      postalCodeRules: [
        (v) => !!v || "Ce champ est requis",
        (v) =>
          (v && v.length == 5) ||
          "Le code postal doit être composé de 5 chiffres",
        (v) => /^\d+$/.test(v) || "Seul les chiffres sont autorisés"
      ],
      phoneRules: [
        (v) => !!v || "Ce champ est requis",
        (v) =>
          (v && v.length == 10) ||
          "Le télephone doit être composé de 10 chiffres",
        (v) => /^\d+$/.test(v) || "Seul les chiffres sont autorisés",
      ],
      cipRules: [
        (v) => !!v || "Ce champ est requis",
        (v) => (v && v.length >= 5) || "Le CIP doit faire minimum 5 caractères",
        (v) => /^\d+$/.test(v) || "Seul les chiffres sont autorisés",
      ],
      checkboxRules: [(v) => !!v || "Vous devez accepter pour continuer"],
      ibanRules: [
        (v) => !!v || "Ce champ est requis",
        (v) =>
          (v && v.length <= 34 && v.length >= 14) ||
          "L'IBAN comprend 14 à 34 caractères",
      ],
      bicRules: [
        (v) => !!v || "Ce champ est requis",
        (v) =>
          (v && v.length <= 11 && v.length >= 8) ||
          "Le BIC comprend 8 à 11 caractères",
      ],
      error: null,
    };
  },
  methods: {
    isNumber: isNumber,
    connect() {
      this.dialog = false;
      localStorage.setItem("loggedIn", true);
      this.loggedIn = true;
      this.$router.push("/home");
    },
    async submitRegister() {
      await this.isValidAddress();
      if (this.$refs.registerForm.validate() && (this.errorCity.length === 0 && this.errorAddress.length === 0 && this.errorPostalCode.length === 0))
        firebase
          .auth()
          .createUserWithEmailAndPassword(
            this.registerForm.email,
            this.registerForm.password
          )
          .then((userCredential) => {
            this.dialog = true;

            usersCollection.doc(userCredential.user.uid).set({
              ...this.pharmacieInfo,
              mail: this.registerForm.email,
              created_at: firebase.firestore.FieldValue.serverTimestamp(),
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              subscription: 0,
              commission_percentage: 5,
              minimalDonationCart:  20,
              type: "pharmacy"
            });
          })
          .catch((err) => {
            setTimeout(() => {
              this.error = null;
            }, 2800);
            console.log(err.code);
            switch (err.code) {
              case "auth/invalid-email":
                this.error = "Votre adresse mail semble être incorrecte.";
                break;
              case "auth/email-already-in-use":
                this.error = "Cette adresse mail est déjà utilisée.";
                break;
              case "auth/weak-password":
                this.error = "Veuillez saisir un mot de passe plus complexe.";
                break;
              default:
                this.error = "Erreur inconnue.";
            }
          });
    },
    async isValidAddress() {
      if(this.pharmacieInfo.address && this.pharmacieInfo.postalCode && this.pharmacieInfo.city) {
        let query = this.pharmacieInfo.address + " " + this.pharmacieInfo.postalCode + " " + this.pharmacieInfo.city;
        await fetch(`https://api-adresse.data.gouv.fr/search/?q=${query}`)
          .then(result => result.json())
          .then(result => {
              if(result.features && result.features.length > 0) { 
                if(result.features[0].properties.postcode === this.pharmacieInfo.postalCode) {
                  this.errorPostalCode = [];
                } else {
                  this.errorPostalCode = ["Veuillez saisir une adresse valide"];
                }
                if(this.removeDiatrics(result.features[0].properties.name.toLowerCase()) === this.removeDiatrics(this.pharmacieInfo.address.toLowerCase())) {
                  this.errorAddress = [];
                } else {
                  this.errorAddress = ["Veuillez saisir une adresse valide"];
                }
                if(this.removeDiatrics(result.features[0].properties.city.toLowerCase()) === this.removeDiatrics(this.pharmacieInfo.city.toLowerCase())) {
                  this.errorCity = [];
                } else {
                  this.errorCity = ["Veuillez saisir une adresse valide"];
                }
              } else {
                  this.errorCity = ["Veuillez saisir une adresse valide"];
                  this.errorAddress = ["Veuillez saisir une adresse valide"];
                  this.errorPostalCode = ["Veuillez saisir une adresse valide"];
              }
              
          }, error => {
              console.error(error);
          });
      }
    },
    removeDiatrics(s) {
        let r=s.toLowerCase();
        r = r.replace(new RegExp(/\s/g),"");
        r = r.replace(new RegExp(/[àáâãäå]/g),"a");
        r = r.replace(new RegExp(/æ/g),"ae");
        r = r.replace(new RegExp(/ç/g),"c");
        r = r.replace(new RegExp(/[èéêë]/g),"e");
        r = r.replace(new RegExp(/[ìíîï]/g),"i");
        r = r.replace(new RegExp(/ñ/g),"n");                
        r = r.replace(new RegExp(/[òóôõö]/g),"o");
        r = r.replace(new RegExp(/œ/g),"oe");
        r = r.replace(new RegExp(/[ùúûü]/g),"u");
        r = r.replace(new RegExp(/[ýÿ]/g),"y");
        r = r.replace(new RegExp(/\W/g),"");
        return r;
    },
  },
  computed: {
    passwordConfirmationRule() {
      return (
        this.registerForm.password === this.registerForm.confirmPassword ||
        "Les mots de passe doivent être identiques"
      );
    },
  },
};
</script>
<style scoped>

.footer-style {
  position: absolute;
  bottom: 0;
  font-size: 10px;
  left: 0;
  width: 100%;
  margin-top: 30px;
  color: #3d3c3c;
}

.footer-style a {
  color: #3d3c3c;
  text-decoration: none;
}

.footer-style a:hover {
  text-decoration: underline;
}

.logo {
  padding: 1%;
}

.logo img {
  width: 10%;
  display: block;
}

.login-container {
  display: flex;
  flex-direction: column;
  background-color: #B5E5DC;
  max-height: 100vh;
  overflow: auto
}

.v-input {
  width: 25vw;
  padding: 0;
  height: 6vh;
  display:flex;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
}

.v-input--checkbox {
  width: 50vw;
  background-color: transparent;
  box-shadow: none;
}

h2 {
  color: #015c53;
}

.error {
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  -webkit-box-shadow: 0 0 10px rgb(255, 0, 0);
  -moz-box-shadow: 0 0 10px rgb(255, 0, 0);
}

.title {
  width: 50vw;
  text-align: left;
  margin: 6vh 1vw 4vh 1vw;
  font-weight: bold;
}

.inscription {
  background-color: #FDD82A !important;
  color:#015c53 !important;
  margin-bottom: 5vh;
  padding: 0 4vw;
  text-transform: none;
  font-weight: bold;
  font-size: 16px;
}

.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 2vw;
  margin-bottom: 10vh;
}
</style>