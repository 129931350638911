
import {
    donationsCollection,
    usersCollection,
} from "../../main";
import moment from "moment";

export async function getDonationAvalaible() {
    if(process.env.VUE_APP_UID_ADMIN == localStorage.getItem("uid")) {
        donationsCollection
        .where("status", "==", "prepared")
        .get()
        .then(async (querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                let donationOrder = doc.data();
                donationOrder.docId = doc.id;

                await usersCollection
                .doc(donationOrder.donorId)
                .get()
                .then(async (pharmacyData) => {
                    donationOrder["minimalDonationCart"] = pharmacyData.data().minimalDonationCart;
                    this.avalaibleDonations.push(donationOrder);
                });
            });
    
    
        });
    } else {
        donationsCollection
        .where("donorId", "==", localStorage.getItem("uid"))
        .get()
        .then(async (querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                let donationOrder = doc.data();
                if(donationOrder.status == "prepared") {
                    donationOrder.docId = doc.id;
                    
                    await usersCollection
                    .doc(localStorage.getItem("uid"))
                    .get()
                    .then(async (pharmacyData) => {
                        donationOrder["minimalDonationCart"] = pharmacyData.data().minimalDonationCart;
                        this.avalaibleDonations.push(donationOrder);
                    });
                }
            });
        });
    }
}

export async function getDonationEnded() {
    if(process.env.VUE_APP_UID_ADMIN == localStorage.getItem("uid")) {
        donationsCollection
        .where("status", "==", "completed")
        .get()
        .then(async (querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                let donationOrder = doc.data();
                if(donationOrder.status == "completed") {
                    donationOrder.docId = doc.id;

                    await usersCollection
                    .doc(donationOrder.donorId)
                    .get()
                    .then(async (pharmacyData) => {
                        donationOrder["minimalDonationCart"] = pharmacyData.data().minimalDonationCart;
                        this.endedDonations.push(donationOrder);
                    });
                }
            });
        });
    } else {
        donationsCollection
        .where("donorId", "==", localStorage.getItem("uid"))
        .where("status", "==", "completed")
        .get()
        .then(async (querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                let donationOrder = doc.data();
                if(donationOrder.status == "completed") {
                    donationOrder.docId = doc.id;

                    await usersCollection
                    .doc(localStorage.getItem("uid"))
                    .get()
                    .then(async (pharmacyData) => {
                        donationOrder["minimalDonationCart"] = pharmacyData.data().minimalDonationCart;
                        this.endedDonations.push(donationOrder);
                    });
                }
            });
        });
    }
}

export async function getDonationInProgress() {
    if(process.env.VUE_APP_UID_ADMIN == localStorage.getItem("uid")) {
        donationsCollection
        .where("status", "==", "inProgress")
        .get()
        .then(async (querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                let donationOrder = doc.data();
                donationOrder.docId = doc.id;

                await usersCollection
                .doc(donationOrder.donorId)
                .get()
                .then(async (pharmacyData) => {
                    donationOrder["minimalDonationCart"] = pharmacyData.data().minimalDonationCart;
                    this.donations.push(donationOrder);
                });
            });
    
    
        });
    } else {
        donationsCollection
        .where("donorId", "==", localStorage.getItem("uid"))
        .get()
        .then(async (querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                let donationOrder = doc.data();
                if(donationOrder.status == "inProgress") {
                    donationOrder.docId = doc.id;
                    
                    await usersCollection
                    .doc(localStorage.getItem("uid"))
                    .get()
                    .then(async (pharmacyData) => {
                        donationOrder["minimalDonationCart"] = pharmacyData.data().minimalDonationCart;
                        this.donations.push(donationOrder);
                    });
                }
            });
        });
    }
}

export async function sendMailDonationAvailable() {
    this.dialogInfo = false;
    donationsCollection.doc(this.order.docId).update({
        status: "prepared",
        dateUpdate: moment().format("YYYY/MM/DD HH:mm:ss"),
    });
}

export async function donationHasBeenRetrieve() {
    this.dialogInfo = false;

    //Remove order from list
    let index = this.orders.indexOf(this.order);
    this.orders.splice(index, 1);

    // Change status of order
    donationsCollection.doc(this.order.docId).update({
        status: "completed",
        dateRetrive: moment().format("YYYY/MM/DD HH:mm:ss"),
        dateUpdate: moment().format("YYYY/MM/DD HH:mm:ss"),
    });
}

export async function updateQuantity() {
    this.showDialogModifyStock = false;

     //Calculate totalPrice
     let total = 0;
     this.order.products.forEach((product) => {
       total += product.price * product.quantity;
     });
     this.order.totalPrice = Math.round(total * 100) / 100;

     //Calculate tax reduction
     this.order.taxReduction = Math.round(total * 66) / 100;
     this.order.dateUpdate = moment().format("YYYY/MM/DD HH:mm:ss");

    // update list of products
    donationsCollection.doc(this.order.docId).update(this.order);
}

