<template>

<div class="container-bg">
<div>
<div class="container-content">

  <div class="listeDons">
    
  
      <div class="tabs_style">
        <tabs>
          <tab title="Acheteur">
            <div class="data">
              <v-simple-table class="tableau">
              <template v-slot:default>
                <thead class="thead_content">
                  <tr>
                    <th class="text-center ">
                      Numéro
                    </th>
                    <th class="text-center ">
                      Acheteur
                    </th>
                    <th class="text-center ">
                      Vendeur
                    </th>
                    <th class="text-center ">
                      PT rem. HT
                    </th>
                    <th class="text-center ">
                      PT rem. TTC
                    </th>
                    <th class="text-center ">
                      Date
                    </th>
                    <th class="text-center">
                      Facture
                    </th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr
                    v-for="(bill, index) in billsClient"
                    :key="index"
                  >
                    <td>{{ bill.billNumber }}</td>
                    <td>{{ bill.client }}</td>
                    <td>{{ bill.seller }}</td>
                    <td>{{ bill.price }}</td>
                    <td>{{ bill.totalTTCPrice }}</td>
                    <td>{{ getDate(bill) }}</td>
                    <td><v-icon small @click="openLink(bill.bill)">mdi-file-pdf-box</v-icon></td>
                  </tr>
                </tbody>
              </template>
              </v-simple-table>
              <div class="nav-pages">{{ billsClient.length }} factures</div>
            </div>
          </tab>
          <tab title="Vendeur">
            <div class="data">
              <v-simple-table class="tableau">
              <template v-slot:default>
                <thead class="thead_content">
                  <tr>
                    <th class="text-center ">
                      Numéro
                    </th>
                    <th class="text-center ">
                      Vendeur
                    </th>
                    <th class="text-center ">
                      PT rem. HT
                    </th>
                    <th class="text-center ">
                      PT rem. TTC
                    </th>
                    <th class="text-center ">
                      Commission HT
                    </th>
                    <th class="text-center ">
                      Commission TTC
                    </th>
                    <th class="text-center ">
                      Date
                    </th>
                    <th class="text-center">
                      Facture
                    </th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr
                    v-for="(bill, index) in billsSeller"
                    :key="index"
                  >
                    <td>{{ bill.billNumber }}</td>
                    <td>{{ bill.seller }}</td>
                    <td>{{ bill.totalHTPrice }}</td>
                    <td>{{ bill.price }}</td>
                    <td>{{ bill.commissionHT }}</td>
                    <td>{{ bill.commissionTTC }}</td>
                    <td>{{ getDate(bill) }}</td>
                    <td><v-icon small @click="openLink(bill.bill)">mdi-file-pdf-box</v-icon></td>
                  </tr>
                </tbody>
              </template>
              </v-simple-table>
              <div class="nav-pages">{{ billsSeller.length }} factures</div>
            </div>
          </tab>
        </tabs>
      </div>

 </div>
</div>
</div>
</div>

</template>

<script>
import Tab from '../tabs/tab.vue';
import Tabs from '../tabs/tabs.vue';
import { getBillsSeller, getBillsClient } from "./functionOrderFacturation"; 
import moment from "moment";


export default {
  components: {
    Tab,
    Tabs
  },

	data() {
    return {
      pharma: {},
      arrayByDate: [],
      orderList: [],
      billsSeller: [],
      billsClient: [],
      uidAdmin: process.env.VUE_APP_UID_ADMIN,
		}
  },
  async mounted() {
    moment.locale("fr");
    this.billsSeller = await this.getBillsSeller(this.checkAdmin());
    this.billsClient = await this.getBillsClient(this.checkAdmin());
  },
  methods: {
    getBillsSeller: getBillsSeller,
    getBillsClient: getBillsClient,

    getDate(bill) {
      let dateString = moment(bill.date, "YYYY/MM/DD").format("DD/MM/YYYY");
      return dateString;
    },

    openLink(url) {
      window.open(url);
    },

    checkAdmin() {
      let valResult = this.uidAdmin === localStorage.getItem("uid");
      return valResult;
    },

  }
}
</script>

<style scoped>

  .thead_content th:first-child {
    border-radius: 30px 0 0 30px;
  }
  .thead_content th:last-child {
    border-radius: 0 30px 30px 0;
  }
  .thead_content {
    background-color: #FFFFFF;
  }

  .text-center {
    font-size: 10px;
  }

  .tabs_style {
    background-color: #FFFFFF;
    border-radius: 20px 20px 0 0;
  }

  .tab_content {
    margin: 10px 30px 20px 30px; /* top left bottom right */
  }

  .v-data-table {
    margin: 0 2vw;
    border-radius: 20px !important;
    border: 1 solid rgba(0, 0, 0, 1) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    max-height : 70vh !important;
    overflow: auto !important;
  }

  .nav-pages {
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    font-size: 10px;
  }

</style>
