<template>
   <div class="container-bg">
        <div>
            <div class="container-content">

              <div class="cancel">
                <h1>Paiement annulé</h1>
                <p>Le paiement a été annulé. Si vous souhaitez réessayer, cliquez sur le bouton ci-dessous.</p>
                <button class="rounded-pill" @click="retryPayment">Réessayer</button>
              </div>

            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      retryPayment() {
        this.$router.push('/payment');
      },
    },
  };
  </script>
  
<style scoped>

.cancel {
    height: 92vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

button {
  padding: 1% 3%;
  margin-top: 2vh;
  height: 5vh !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  color: #015c53;
  background-color: #FDD82A;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center !important;
}

</style>