import { ordersCollection, productsCollection } from "../../main";

export async function kdzUpdateOrderStatus(orderId, status) {
    try {
        console.log(orderId, status);
        await ordersCollection.doc(orderId).update({
            status: status,
        });
        return true;
    } catch (error) {
        return error;
    }
}

export async function kdzPublishProduct(product) {
    try {
        await productsCollection.doc(product.id).update({ productState: "online" })
        return true;
    } catch (error) {
        return error;
    }
}

export async function cancelOrderProduct(order, product) {
    try {
        let productIndex = order.products.findIndex((e) => e.ean === product.ean);
        if (productIndex < 0) {
            alert("Erreur d'index du produit");
            return;
        }
        if (order.products[productIndex].quantity === 1) {
            order.products.splice(productIndex, 1);
        } else {
            order.products[productIndex].quantity -= 1;
        }
        let totalPrice = 0;
        for (let i = 0; i < order.products.length; i++) {
            totalPrice += order.products[i].price * order.products[i].quantity;
        }
        if (order.orderType == "delivery") {
            totalPrice += 5;
        }
        if (order.promoCode > 0) {
            totalPrice = totalPrice * order.promoCode / 100;
        }

        order.totalPrice = totalPrice;

        await ordersCollection.doc(order.docId).update({
            products: order.products,
            totalPrice: totalPrice
        });
    } catch (error) {
        alert(error);
    }

}

export function getTotalPriceWithoutDiscount(order) {
    console.log(order);
    return 10;
}