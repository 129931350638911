<template>
    <div class="container-bg">
      <div>
        <div class="container-content">
          <div class="infos">
            <div class="left-content">
              <div>
                <h2>Total CA sauvé</h2>
                <span class="data">{{ formatPrice(ca) }} €</span>
              </div>
              <div>
                <h2>Nombre d'invendus sauvés</h2>
                <span class="data">{{ invendus }}</span>
              </div>
              <div>
                <h2>Produits autorisés (ventes et dons)</h2>
                <ul>
                  <li>Cosmétiques</li>
                  <span class="products">hydratant, gommage, maquillage, solaire, anti-rides...</span>
                  <li>Hygiène</li>
                  <span class="products">gels douche, shampoing, déodorant, dentifrice...</span>
                  <li>Compléments alimentaires</li>
                  <span class="products">vitamines, détoxifiant, équilibre féminin...</span>
                  <li>Bébé</li>
                  <span class="products">lait infantile, liniment, gel lavant, biberon, tétine...</span>
                  <li>Parapharmacie (autres catégories)</li>
                  <li>Dispositifs médicaux (classe I ou IIa)</li>
                  <span class="products">compresses, pansements, contentions, chaussures...</span>
                </ul>
              </div>
            </div>
            <div class="right-content">
              <div>
                <h2>Quantité CO2 sauvés</h2>
                <span class="data">{{ formatQuantity(co2) }}</span>
              </div>
              <div>
                <h2>Equivalence de CO2 sauvés</h2>
                <span class="data">{{ formatEquivalence(co2) }}</span>
              </div>
              <div>
                <h2>Produits non autorisés (ventes et dons)</h2>
                <ul>
                  <li>Médicaments</li>
                  <li>Dispositifs médicaux (classe IIb ou III)</li>
                </ul>
                <ul>
                  <li class="products">Classe IIb : machines de dialyse, couveuses pour nouveaux nés, oxymètres, respirateurs, préservatifs masculins, trocarts stériles,
                    moniteurs de signes vitaux, implants dentaires
                  </li>
                  <li class="products">Classe III : cathéters destinés au coeur, spermicides, neuro-endoscopes, aiguilles trans-septales, applicateurs d'agrafe chirurgicale, pinces souples à biopsie,
                    pompes cardiaques, prothèses articulaires de la hanche
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer-style">
          v2.4.2
        </footer>
      </div>
    </div>
  </template>
  
  <script>
  import { ordersCollection, productsRemovedCollection } from '../../main';
  
  export default {
    data() {
      return {
        ca: 0,
        invendus: 0,
        co2: 0,
        uidAdmin: process.env.VUE_APP_UID_ADMIN
      };
    },
    mounted() {
      ordersCollection
        .where("status", "==", "completed")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            if (data.products.some(product => this.checkOwner(product)) || this.checkAdmin()) {
              this.ca += parseFloat(data.totalPrice);
              data.products.forEach(product => {
                if (parseFloat(product.quantity))
                  this.invendus += parseFloat(product.quantity);
                this.addCo2(product.ean, product.quantity);
              });
            }
          });
        });
    },
    methods: {
      async addCo2(ean, quantity) {
        const querySnapshot = await productsRemovedCollection.where("ean", "==", ean).get();
        querySnapshot.forEach(doc => {
          let data = doc.data();
          if (data.co2 && quantity) {
            this.co2 += Math.ceil(data.co2 / 1000 * quantity);
          }
        });
      },
      checkAdmin() {
        return this.uidAdmin === localStorage.getItem("uid");
      },
      checkOwner(item) {
        return item ? item.uid === localStorage.getItem("uid") : false;
      },
      formatPrice(price) {
        const floatPrice = parseFloat(price);
        if (isNaN(floatPrice)) {
          return "Prix invalide";
        }
        return floatPrice.toFixed(2).replace('.', ',');
      },
      formatQuantity(co2) {
        return Math.round(co2) + " kg d'émission de CO2";
      },
      formatEquivalence(co2) {
        return Math.round(co2 * 3.74) + " litres d'eau en bouteille";
      }
    }
  };
  </script>

<style scoped>


.container-content {
    padding-top: 1vh;
    height: 92vh;
}

.infos {
    display: flex;
    justify-content: center;
    gap: 3vw;
    max-height: 88vh;
    overflow:auto;
}

.left-content, .right-content {
    height: 8_vh;
    gap: 2vh;
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 3vh 0;
}

.left-content div, .right-content div {
    padding: 2% 5%;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
}

.right-content div {
    padding: 4%;
}

.data {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 40px;
    font-weight: bold;
    color: #015c53;
}

.footer-style {
  background: transparent;
}

h2 {
    color: rgba(0, 0, 0, 0.66);
}

li:first-of-type {
    padding-top: 1vh;
}

li {
    font-size: 20px;
    font-weight: bold;
    color: #015c53;
    padding: 0.7vh 0;
}

.products {
    margin-left: 1vw;
    font-weight: bold;
    color: rgb(103, 100, 97);
    font-size: 18px;
}

@media screen and (max-width: 1300px) {

    .infos {
        padding-top: 0vh;
    }

    .data {
        font-size: 20px;
    }

    li {
        font-size: 14px;
        padding: 0.2vh 0;
    }

    h2 {
        font-size: 16px;
    }

    .products {
        font-size: 12px
    }
}

</style>