// import moment from "moment";
import { usersCollection, billsCollection } from "../../main";
import "firebase/compat/database";

async function getPharmacy(uid = localStorage.getItem("uid")) {
  return await usersCollection
    .doc(uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      }
    });
}
export async function getBillsSeller(isAdmin) {
  let bills = [];
  if (isAdmin) {
    return await billsCollection.orderBy("date", "desc")
    .get().then(async (querySnapshot) => {
      for (let doc of querySnapshot.docs) {
        let data = doc.data();
        let bill = data;
        if(data.type === undefined || data.type !== "client") {
          let pharma = await getPharmacy(data.pharmacyId);
          bill.pharmacyName = pharma.denominationName;
          bills.push(data);
        }
      }
      return bills.sort((a, b) => {
        let aName = a.date+a.pharmacyName;
        let bName = b.date+b.pharmacyName;
        return bName > aName;
      });
    });
  }
  return await billsCollection
    .where("pharmacyId", "==", localStorage.getItem("uid"))
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        if(data.type === undefined || data.type !== "client") {
          bills.push(data);
        }
      });

      bills.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
      });
      
      return bills;
    });
}

export async function getBillsClient(isAdmin) {
  let billsClient = [];
  if (isAdmin) {
    return await billsCollection.orderBy("date", "desc")
    .get().then(async (querySnapshot) => {
      for (let doc of querySnapshot.docs) {
        let data = doc.data();
        let bill = data;
        if(data.type === "client") {
          let pharma = await getPharmacy(data.pharmacyId);
          bill.pharmacyName = pharma.denominationName;
          billsClient.push(data);
        }
      }
      return billsClient.sort((a, b) => {
        let aName = a.date+a.pharmacyName;
        let bName = b.date+b.pharmacyName;
        return bName > aName;
      });
    });
  }
  return await billsCollection
    .where("pharmacyId", "==", localStorage.getItem("uid"))
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        if(data.type === "client") {
          billsClient.push(data);
        }
      });

      billsClient.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
      });
      
      return billsClient;
    });
}
