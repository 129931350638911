import Vue from 'vue';
import Router from 'vue-router';
import Login from './components/auth/login.vue';
import Home from './components/home/home.vue';
import Donations from './components/donation/donations.vue'
import Sales from './components/sales/sales.vue'
import Ciblage from './components/targeting/targeting.vue'
import CancelPayment from './components/targeting/cancelPayment.vue'
import Success from './components/targeting/success.vue'
import PaymentPage from './components/targeting/payment.vue'
import RegisterPharmacy from './components/auth/registerPharmacy';
import RegisterShop from './components/auth/registerShop';
import ArrayData from './components/arrayData/arrayData';
import ForgotPassword from './components/auth/forgotPassword';
import firebase from "firebase/compat/app";
import account from './components/account/account'
import facturation from "./components/facturation/facturation";
import sommaryOrder from './components/sommaryOrder';
import orderInProgress from './components/orderInProgress';
import about from './components/about/about';
import { usersCollection } from './main';
import moment from 'moment';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login', component: Login,
            beforeEnter: (to, from, next) => {
                const loggedIn = localStorage.getItem('loggedIn');
                if (loggedIn === 'true') {
                    next('/accueil')
                } else
                    next();
            }
        },
        {
            path: "/forgot-password",
            component: ForgotPassword,
            beforeEnter(to, from, next) {
                const user = firebase.auth().currentUser;
                if (user) {
                    next("/home");
                } else {
                    next();
                }
            },
        },
        {
            path: '/register', component: RegisterPharmacy,
            beforeEnter: (to, from, next) => {
                const loggedIn = localStorage.getItem('loggedIn');
                if (loggedIn === 'true') {
                    next('/accueil')
                } else {
                    if (to.query.token != 'qhNzJFTR7xSGpqn6N5i4AyQC7DY9RisD7pcy9CNx')
                        next('/')
                    else
                        next();
                }

            }
        },
        {
            path: '/registerShop', component: RegisterShop,
            beforeEnter: (to, from, next) => {
                const loggedIn = localStorage.getItem('loggedIn');
                if (loggedIn === 'true') {
                    next('/accueil')
                } else {
                    if (to.query.token != 'Ss5dtSpNhzcEo0PVvwbxe0RN9T05ifVP')
                        next('/')
                    else
                        next();
                }

            }
        },
        {
            path: '/accueil', component: Home,
            beforeEnter: (to, from, next) => {
                const loggedIn = localStorage.getItem('loggedIn');
                if (loggedIn === 'true') {
                    next()
                } else
                    next('/login');
            }
        },
        {
            path: "/account",
            component: account,
            beforeEnter(to, from, next) {
                const user = firebase.auth().currentUser;
                if (user) {
                    next();
                } else {
                    next("/home");
                }
            },
        },
        {
            path: "/unsold", component: ArrayData,
            beforeEnter(to, from, next) {
                const user = firebase.auth().currentUser;
                if (user) {
                    next();
                } else {
                    next("/home");
                }
            },
        },        
        {
            path: "/sales", component: Sales,
            beforeEnter(to, from, next) {
                const user = firebase.auth().currentUser;
                if (user) {
                    next();
                } else {
                    next("/home");
                }
            },
        },
        {
            path: '/targeting',
            component: Ciblage,
            beforeEnter: async (to, from, next) => {
              try {
                const user = firebase.auth().currentUser;
                if (!user) {
                  next('/home');
                  return;
                }
                const userAuth = user.uid;
                const res = await usersCollection.doc(userAuth).get();
                const data = res.data();
                const currentDate = moment();
                const subscriptionExpiry = moment(data.subscriptionExpiry, "YYYY/MM/DD HH:mm:ss");
    
                if (subscriptionExpiry.isAfter(currentDate) || process.env.VUE_APP_UID_ADMIN === localStorage.getItem("uid")) {
                        next();
                } else {
                    next({ path: '/payment' });
                }
              } catch (error) {
                console.error('Error checking payment status:', error);
                next('/cancel'); 
              }
            },
          },
          {
            path: '/payment',
            component: PaymentPage,
            beforeEnter(to, from, next) {
                const user = firebase.auth().currentUser;
                if (user) {
                    next();
                } else {
                    next("/home");
                }
            },
          },
          {
            path: '/cancel',
            component: CancelPayment,
          },
          {
            path: '/success',
            component: Success,
          },
          {
            path: '/success/:session_id', // Route dynamique avec session_id comme paramètre
            component: Success,
          },
        {
            path: "/donations", component: Donations,
            beforeEnter(to, from, next) {
                const user = firebase.auth().currentUser;
                if (user) {
                    next();
                } else {
                    next("/home");
                }
            },
        },
        {
            path: "/sommaryOrder",
            component: sommaryOrder,
            beforeEnter(to, from, next) {
                const user = firebase.auth().currentUser;
                if (user) {
                    next();
                } else {
                    next("/home");
                }
            },
        },
        {
            path: "/orderInProgress",
            component: orderInProgress,
            beforeEnter(to, from, next) {
                const user = firebase.auth().currentUser;
                if (user) {
                    next();
                } else {
                    next("/home");
                }
            },
        },
        {
            path: "/facturation",
            component: facturation,
            beforeEnter(to, from, next) {
                const user = firebase.auth().currentUser;
                if (user) {
                    next();
                } else {
                    next("/home");
                }
            },
        },
        {
            path: "/about",
            component: about,
            beforeEnter(to, from, next) {
                const user = firebase.auth().currentUser;
                if (user) {
                    next();
                } else {
                    next("/home");
                }
            },
        },
        {
            path: '*',
            redirect: '/accueil'
        }
    ],
});

export default router